<template>
    <div class="module-card mx-auto">
        <button @click="makeFavorite()" class="btn btn-fav">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25.665"
                height="22.583"
                viewBox="0 0 25.665 22.583"
            >
                <path
                    d="M22.268,3.723a6.587,6.587,0,0,0-8.988.655l-.949.978-.949-.978a6.587,6.587,0,0,0-8.988-.655,6.917,6.917,0,0,0-.477,10.014l9.321,9.624a1.51,1.51,0,0,0,2.182,0l9.321-9.624a6.912,6.912,0,0,0-.472-10.014Z"
                    transform="translate(0.502 -1.745)"
                    :fill="[cardData.is_favorite ? '#FF0000' : '#fff']"
                />
            </svg>
        </button>
        <span v-if="cardData.workshop" class="workshop d-inline-block">Workshop</span>
        <div class="cursor-pointer" @click="showModuleDetails(cardData)">
            <!-- <img
                :src="cardData.image"
                class="img mw-100 mb-3"
                alt="module name"
            /> -->
            <progressive-img :src="cardData.image"
            class="img mw-100 mb-3"
            alt="module name" />
        </div>
        <ul
            class="
                d-flex
                flex-wrap
                justify-content-center justify-content-md-start
                align-items-center
                contains
                mb-3
            "
        >
            <li>
                <div
                    v-if="cardData.module_type == 'article'"
                    class="contains-item d-flex align-items-center">
                    <span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 13.657 12.519"
                        >
                            <g transform="translate(0 -1)">
                                <path
                                    d="M8.125,8H5A1,1,0,0,0,4,9v3.13a1,1,0,0,0,1,1h3.13a1,1,0,0,0,1-1V9A1,1,0,0,0,8.125,8ZM5,8.854h3.13A.142.142,0,0,1,8.268,9v1.471a1.024,1.024,0,0,0-1.346.186l-.3.369a.131.131,0,0,1-.213.011,1,1,0,0,0-1.411,0l-.148.148V9A.142.142,0,0,1,5,8.854Z"
                                    transform="translate(-1.724 -3.017)"
                                    fill="red"
                                />
                                <path
                                    d="M11.95,1H1.707A1.709,1.709,0,0,0,0,2.707v9.1a1.709,1.709,0,0,0,1.707,1.707H11.95a1.709,1.709,0,0,0,1.707-1.707v-9.1A1.709,1.709,0,0,0,11.95,1Zm0,11.381H1.707a.57.57,0,0,1-.569-.569V3.845H12.519v7.966A.57.57,0,0,1,11.95,12.381Z"
                                    fill="red"
                                />
                                <path
                                    d="M16.845,11.138H14.569a.569.569,0,1,1,0-1.138h2.276a.569.569,0,0,1,0,1.138Z"
                                    transform="translate(-6.034 -3.879)"
                                    fill="red"
                                />
                                <path
                                    d="M16.845,15.138H14.569a.569.569,0,1,1,0-1.138h2.276a.569.569,0,0,1,0,1.138Z"
                                    transform="translate(-6.034 -5.603)"
                                    fill="red"
                                />
                                <path
                                    d="M12.535,19.138H4.569a.569.569,0,1,1,0-1.138h7.966a.569.569,0,1,1,0,1.138Z"
                                    transform="translate(-1.724 -7.327)"
                                    fill="red"
                                />
                            </g>
                        </svg>
                    </span>
                    <span class="text-capitalize">{{ cardData.module_type }}</span>
                </div>
                <div
                    v-if="cardData.module_type == 'video'"
                    class="contains-item d-flex align-items-center">
                    <span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18.778"
                            height="12.519"
                            viewBox="0 0 18.778 12.519"
                        >
                            <path
                                d="M10.96,4.5h-9.4A1.558,1.558,0,0,0,0,6.058v9.4a1.558,1.558,0,0,0,1.558,1.558h9.4a1.558,1.558,0,0,0,1.558-1.558v-9.4A1.558,1.558,0,0,0,10.96,4.5Zm6.175,1.229L13.562,8.194v5.131l3.573,2.461a1.045,1.045,0,0,0,1.643-.841V6.57A1.046,1.046,0,0,0,17.135,5.729Z"
                                transform="translate(0 -4.5)"
                                fill="red"
                            />
                        </svg>
                    </span>
                    <span class="text-capitalize">{{ cardData.module_type }}</span>
                </div>
                <div
                    v-if="cardData.module_type == 'quiz'"
                    class="contains-item d-flex align-items-center">
                    <span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="13.944"
                            height="18.593"
                            viewBox="0 0 13.944 18.593"
                        >
                            <path
                                d="M12.2,2.324H9.3a2.324,2.324,0,1,0-4.648,0H1.743A1.743,1.743,0,0,0,0,4.067V16.849a1.743,1.743,0,0,0,1.743,1.743H12.2a1.743,1.743,0,0,0,1.743-1.743V4.067A1.743,1.743,0,0,0,12.2,2.324ZM3.486,15.4a.872.872,0,1,1,.872-.872A.869.869,0,0,1,3.486,15.4Zm0-3.486a.872.872,0,1,1,.872-.872A.869.869,0,0,1,3.486,11.911Zm0-3.486a.872.872,0,1,1,.872-.872A.869.869,0,0,1,3.486,8.425ZM6.972,1.453a.872.872,0,1,1-.872.872A.869.869,0,0,1,6.972,1.453ZM11.62,14.816a.291.291,0,0,1-.291.291H6.1a.291.291,0,0,1-.291-.291v-.581a.291.291,0,0,1,.291-.291H11.33a.291.291,0,0,1,.291.291Zm0-3.486a.291.291,0,0,1-.291.291H6.1a.291.291,0,0,1-.291-.291v-.581a.291.291,0,0,1,.291-.291H11.33a.291.291,0,0,1,.291.291Zm0-3.486a.291.291,0,0,1-.291.291H6.1a.291.291,0,0,1-.291-.291V7.263A.291.291,0,0,1,6.1,6.972H11.33a.291.291,0,0,1,.291.291Z"
                                fill="#a9abc4"
                            />
                        </svg>
                    </span>
                    <span class="text-capitalize">{{ cardData.module_type }}</span>
                </div>
                <div
                    v-if="cardData.module_type == 'cme'"
                    class="contains-item d-flex align-items-center">
                    <span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22.488"
                            height="22.408"
                            viewBox="0 0 22.488 22.408"
                        >
                            <g transform="translate(-54.701)">
                                <g transform="translate(54.701 0)">
                                    <g transform="translate(0 0)">
                                        <path
                                            d="M67.1.4A1.855,1.855,0,0,0,68.8.72a1.855,1.855,0,0,1,2.15.833,1.855,1.855,0,0,0,1.473.912,1.855,1.855,0,0,1,1.7,1.553A1.855,1.855,0,0,0,75.173,5.4,1.855,1.855,0,0,1,76.2,7.466a1.855,1.855,0,0,0,.474,1.666,1.855,1.855,0,0,1,.213,2.3,1.855,1.855,0,0,0-.16,1.725,1.855,1.855,0,0,1-.631,2.218,1.855,1.855,0,0,0-.772,1.551,1.855,1.855,0,0,1-1.39,1.84,1.855,1.855,0,0,0-1.28,1.167,1.855,1.855,0,0,1-1.961,1.214,1.855,1.855,0,0,0-1.616.626,1.855,1.855,0,0,1-2.267.424,1.855,1.855,0,0,0-1.733,0,1.855,1.855,0,0,1-2.267-.424,1.855,1.855,0,0,0-1.616-.626,1.855,1.855,0,0,1-1.961-1.214,1.855,1.855,0,0,0-1.28-1.167,1.855,1.855,0,0,1-1.39-1.84,1.855,1.855,0,0,0-.772-1.551,1.855,1.855,0,0,1-.631-2.218A1.855,1.855,0,0,0,55,11.428a1.855,1.855,0,0,1,.213-2.3,1.855,1.855,0,0,0,.474-1.666A1.855,1.855,0,0,1,56.718,5.4a1.855,1.855,0,0,0,1.044-1.383,1.855,1.855,0,0,1,1.7-1.553,1.855,1.855,0,0,0,1.473-.912A1.855,1.855,0,0,1,63.089.72,1.855,1.855,0,0,0,64.792.4,1.855,1.855,0,0,1,67.1.4Z"
                                            transform="translate(-54.701 0)"
                                            fill="#e8ae4d"
                                        />
                                        <g transform="translate(3.06 3.02)">
                                            <path
                                                d="M122.734,62.858A8.609,8.609,0,0,0,116.9,54.71a8.184,8.184,0,0,0,0,16.3A8.608,8.608,0,0,0,122.734,62.858Z"
                                                transform="translate(-109.489 -54.674)"
                                                fill="#ffe07d"
                                            />
                                            <path
                                                d="M225.193,54.07a8.21,8.21,0,0,0-1.823.2,8.186,8.186,0,0,1,0,15.959,8.184,8.184,0,1,0,1.823-16.163Z"
                                                transform="translate(-217.009 -54.07)"
                                                fill="#ffd064"
                                            />
                                            <text
                                                transform="translate(1.605 10.598)"
                                                fill="#df9d2e"
                                                font-size="6"
                                                font-family="NotoSans-Bold, Noto Sans"
                                                font-weight="700"
                                            >
                                                <tspan x="0" y="0">CME</tspan>
                                                <tspan y="0">‏</tspan>
                                            </text>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </span>
                    <span class="text-capitalize">{{ cardData.module_type }}</span>
                </div>
                <div
                    v-if="cardData.module_type == 'ppt'"
                    class="contains-item d-flex align-items-center">
                    <span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 22.841 23.747"
                        >
                            <g transform="translate(-11.228)">
                                <path
                                    d="M34.069,3.51a1.527,1.527,0,0,0-1.525-1.525H25.355c-.032,0-.063,0-.094.005V0L11.228,1.873V21.826L25.26,23.747V21.7c.032,0,.063,0,.094,0h7.189a1.527,1.527,0,0,0,1.525-1.525ZM14.147,12.723a1.3,1.3,0,0,1-.973.347,1.454,1.454,0,0,1-.226-.016v1.174l-.644-.02v-3.2a4.761,4.761,0,0,1,.883-.1,1.3,1.3,0,0,1,.915.236,1,1,0,0,1,.328.772A1.142,1.142,0,0,1,14.147,12.723Zm2.775,0a1.484,1.484,0,0,1-1.08.366,1.709,1.709,0,0,1-.251-.017v1.236l-.713-.022V10.922a5.532,5.532,0,0,1,.978-.106,1.5,1.5,0,0,1,1.015.247,1.029,1.029,0,0,1,.364.814A1.17,1.17,0,0,1,16.922,12.723ZM20.268,11.4l-1.026.024v2.995l-.825-.025V11.442l-.946.022v-.68l2.8-.1Zm2.7,3.131-.377-.721c-.153-.275-.251-.479-.367-.705h-.012c-.085.224-.188.425-.314.693l-.334.691-1.015-.031,1.139-1.917-1.1-1.867,1.022-.036.353.695c.121.233.212.422.308.64h.013c.1-.25.177-.425.281-.654l.352-.727,1.1-.039-1.2,1.962,1.264,2.051ZM33.2,20.177a.654.654,0,0,1-.654.654H25.355a.633.633,0,0,1-.094-.009v-8.9a3.987,3.987,0,0,0,6.207-.389L28.189,9.477,25.26,10.761v-.476l2.675-1.173V5.251a3.985,3.985,0,0,0-2.675,1.29V2.866a.64.64,0,0,1,.094-.01h7.189a.654.654,0,0,1,.654.654V20.177Z"
                                    transform="translate(0)"
                                    fill="red"
                                />
                                <path
                                    d="M54.135,284.379a1.039,1.039,0,0,0-.274.036v.97a.9.9,0,0,0,.221.019.521.521,0,0,0,.578-.547A.452.452,0,0,0,54.135,284.379Z"
                                    transform="translate(-40.913 -272.906)"
                                    fill="red"
                                />
                                <path
                                    d="M119.673,282.731a1.2,1.2,0,0,0-.3.039v1.021a1.037,1.037,0,0,0,.245.02.566.566,0,0,0,.641-.576C120.256,282.909,120.043,282.722,119.673,282.731Z"
                                    transform="translate(-103.778 -271.324)"
                                    fill="red"
                                />
                                <path
                                    d="M438.07,128.806v3.831l3.33,2.089a3.988,3.988,0,0,0-3.33-5.92Z"
                                    transform="translate(-409.621 -123.609)"
                                    fill="red"
                                />
                                <rect
                                    width="4.357"
                                    height="0.65"
                                    transform="translate(25.878 14.75)"
                                    fill="red"
                                />
                                <rect
                                    width="4.357"
                                    height="0.651"
                                    transform="translate(25.878 16.405)"
                                    fill="red"
                                />
                                <rect
                                    width="4.357"
                                    height="0.651"
                                    transform="translate(25.878 18.035)"
                                    fill="red"
                                />
                            </g>
                        </svg>
                    </span>
                    <span class="text-uppercase">{{ cardData.module_type }}</span>
                </div>
            </li>
        </ul>
        <div class="progress mb-3">
            <div :style="`width: ${cardData.progress_bar}`" class="body"></div>
        </div>
        <div class="cursor-pointer" @click="showModuleDetails(cardData)">
            <h5 class="title text-capitalize">{{ cardData.name }}</h5>
        </div>
        <p class="sepciality text-capitalize">{{ cardData.speciality }}</p>
        <div class="d-flex justify-content-between align-items-center mb-1">
            <div class="d-flex align-items-center">
                <img
                    :src="cardData.speak_info_image"
                    width="45"
                    height="45"
                    class="img-circle"
                    :alt="cardData.speak_info_name"
                />
                <small class="owner-name mx-2 text-capitalize">{{
                    cardData.speak_info_name
                }}</small>
            </div>
            <small v-if="cardData.owned" class="price owned">
                Owned
            </small>
            <small v-else class="price" :class="[cardData.payment ? 'red' : 'free']">
                <template v-if="typeof cardData.payment !== String">
                    {{ cardData.payment ? `${cardData.payment}$` : 'Free' }}
                </template>
                <template v-else>
                    {{ cardData.payment ? cardData.payment : 'Free' }}
                </template>
            </small>
        </div>
    </div>
</template>

<script>
import { storeFavorite } from "@/API/favorite";
export default {
    name: "ModuleCard",
    props: ["cardData"],
    computed: {
        progressWidth() {
            return this.cardData?.progress
                ? `width: ${this.cardData?.progress}%`
                : 0;
        },
    },
    methods: {
        showModuleDetails(module) {
            const isRestricted = module.is_restricted
            if (!isRestricted) this.$router.push(`/module/${module.id}/${module.code}`)
            else
            this.$notify(this.$t('restrictedModule'));
        },
        makeFavorite() {
            const data = {
                module_id: this.cardData.id
            }
            storeFavorite(data).then(res => {
                if (res.status == 200) {
                    const response = res.data;
                    // console.log(response);
                    this.$notify(response.message);
                    this.$emit('storedAsFavorite');
                }
            });
        }
    }
};
</script>
